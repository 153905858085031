@import "../main.styles.scss";

.website-navigation {
  &__list {
    list-style-type: none;
    display: flex;
  }
  &__item {
    &:not(:last-child) {
      margin-right: 3rem;
    }

    @include respond(tab-port) {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }

    &:hover {
      .website-navigation__link,
      .website-navigation__social-icon {
        @include bounce-animation;
      }
    }
  }
  &__link {
    color: $color-white;
    text-decoration: none;
    font-size: 1.6rem;
    font-weight: 300;
    display: inline-block;

    @include respond(tab-port) {
      font-size: 2rem;
    }
  }
  &__social-icon {
    fill: $color-white;
  }
  &__link,
  &__social-icon {
    @include text-drop-shadow;
    @include text-drop-shadow-hover;
  }

  &__main {
    margin-left: 5rem;
  }

  &__social-icon {
    width: 2rem;
    height: 2rem;

    @include respond(tab-port) {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  &__mobile {
    width: 100%;
    margin: 0;

    &__list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__item {
      //get rid of the not:(:last-child) margin
      margin-right: 0 !important;
    }
  }
}
