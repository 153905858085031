@import '../main.styles.scss';

.popup {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    //z-index: 10000;
    z-index: -1000;

    display: flex;
    justify-content: center;
    align-items: center;

    &__wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        @include translucent-white-background;
        @include basic-drop-shadow;
        width: .1vw;
        height: .1vh;
        //overflow-y: scroll;

        transition: all .3s;

            ///////////////////////////////////////////
            //// functionalities for active popup ////
            /////////////////////////////////////////
            &--active {
                width: 90vw;
                height: 90vh;
            }
    }

    &__close {
        position: absolute;
        right: 0;
        align-self: start;

        display: flex;
        align-items: center;

        margin: 1rem;
        width: 3.5rem;
        height: 3.5rem;

        transition: all .3s;

        &:hover {
            cursor: pointer;
        }

        &:hover &--button {
            transform: rotate(-180deg);
        }

        &--button {
            transition: all .3s;
            width: 100%;
            height: .5rem;
            &::before,
            &::after {
                content: '';
                display: block;
                //width: 100%;
                height: .5rem;
                background-color: $color-brand-orange;
                @include basic-drop-shadow;
            }
            &::after {
                position: relative;
                top: -.5rem;
                transform: rotate(-45deg);
            }
            &::before {
                transform: rotate(45deg);
            }
            &--active {
                //transform: rotate(270deg);
            }
        }
    }

    &__contentFill {
        max-width: 100%;
        max-height: 100%;
        //background-repeat: no-repeat;
        //background-position: center;
    }

    ///////////////////////////////////////////
    //// functionalities for active popup ////
    /////////////////////////////////////////

    &--active {
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1000;
    }

}