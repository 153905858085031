@import "../main.styles.scss";

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__logo {
    &--wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 5rem;
      color: $color-white;
    }

    &--text {
      @include text-drop-shadow;
    }
  }
}
