@import "../main.styles.scss";

.logo {
  width: 7rem;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    @include jello-animation;
    &__svg {
      @include hover(yellow, filter);
    }
  }

  &__svg {
    @include text-drop-shadow;
  }
}
