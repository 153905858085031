@import '../main.styles.scss';

.gallery-builder {
    width: 100%;
    height: 100%;

    margin-bottom: 2rem;

    &__filter {
        display: flex;
        //justify-content: space-between;
        align-items: center;

        background-color: lightgrey;
        padding: $basic-padding;

        @include translucent-white-background;
        @include basic-drop-shadow;

        &--text {
            font-weight: bold;
            color: $color-brand-orange;
            font-size: 1.8rem;
        }

        &--buttons {
            width: 100%;
            display: flex;
            justify-content: space-around;

            @include respond(phone) {
                flex-wrap: wrap;
            }
        }

        @include respond(tab-port) {
            flex-direction: column;

            &--text {
                align-self: start;
            }
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, auto);
        grid-gap: 1rem;

        &:not(:last-child) {
            margin-bottom: 1rem;
        }

        &__box:nth-child(2) {
            grid-column: 3 / 5;
            grid-row: 1 / -1;
        }

        &__box:first-child {
            grid-column: 1 / 3;
            grid-row: 1;
        }

        @include respond(tab-port) {
            &__box:nth-child(2) {
                grid-row: 1;
                grid-column: 1 / -1;
            }
            &__box:first-child {
                grid-row: 2;
                grid-column: 1 / -1;
            }
            &__box:nth-child(3),
            &__box:nth-child(4) {
                grid-row: 3;
            }
            &__box:nth-child(3) {
                grid-column: 1 / 3;
            }
            &__box:nth-child(4) {
                grid-column: 3 / -1;
            }
        }
    }

    &__grid:nth-child(even) {
        div:first-child {
            grid-column: 1 / 3;
            grid-row: 2;
        }

        @include respond(tab-port) {
            div:nth-child(2) {
                grid-row: 3;
                grid-column: 1 / -1;
            }
            div:first-child {
                grid-row: 2;
                grid-column: 1 / -1;
            }
            div:nth-child(3),
            div:nth-child(4) {
                grid-row: 1;
            }
            div:nth-child(3) {
                grid-column: 1 / 3;
            }
            div:nth-child(4) {
                grid-column: 3 / -1;
            }
        }
    }

    &__grid{
        &__box{
            @include translucent-white-background;
            @include basic-drop-shadow;
            transition: all .3s;

            &:before {
                content: '';
                display: block;
                padding-bottom: 75%;

                @include respond(tab-port) {
                    padding-bottom: 50%;
                }
            }

            &:hover {
                cursor: pointer;
                @include basic-drop-shadow-hover;
                transform: rotate(-5deg) scale(1.05);
                background-color: rgba($color-white, 1);
                transform-origin: center;
                border: 1px solid $color-brand-yellow;
            }

            //background-position: center;
            background-size: 100%;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

//////////////////////////////////////////////////////////
/// ID styling for individual portfolio items within the gallery
/////////////////////////////////////////////////////////

#gallery-grid-box {
    &__1,
    &__3,
    &__4,
    &__5,
    &__7,
    &__8 {
        background-position: center;
    }
}

//////////////////////////////////////////////////////////
/// ID styling for individual portfolio items within the portfolio item page
/////////////////////////////////////////////////////////

#gallery-grid-box-portfolioitem {
    &__dyenamiX-logo-and-icon,
    &__dyenamix-logo-variant-DX,
    &__dyenamix-logo-variant-DyX,
    &__dyenamix-logo-variant-DY,
    &__The-Wunder-Wizard,
    &__Wunder-Wizard-Logo,
    &__For-the-Love-of-Fieri,
    &__boogie-boo-happy-emote,
    &__for-the-love-of-fieri-logo,
    &__cheeseburger,
    &__froyo,
    &__Salt-of-the-Earth-full-name-logo,
    &__Double-D-Danny-Dingus-logo,
    &__Danny-Dingus-Circle-logo,
    &__Sucia-Logo,
    &__Sucia-S-logo,
    &__Nikka-T-Logo-silver,
    &__Joseph-Star-Sound-Logo,
    &__essential-education-logo,
    &__arepas-dino-eating-an-arepa,
    &__blue-arepas-dino,
    &__arepas-en-bici-logo-in-orange-circle,
    &__ibs-designs-first-logo,
    &__Juiced-up-ripped-banana {
        background-position: center;
    }
}

//////////////////////////////////////////////////////////
/// old stuff from before the auto grids
/////////////////////////////////////////////////////////

    &__grid-style-1,
    &__grid-style-2 {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, auto);
        grid-gap: 1rem;

        &:not(:last-child) {
            margin-bottom: 1rem;
        }

        &--box-2 {
            grid-column: 3 / 5;
            grid-row: 1 / -1;
        }
    }

    &__grid-style-1 {
        &--box-1 {
            grid-column: 1 / 3;
            grid-row: 1;
        }

        @include respond(tab-port) {
            &--box-2 {
                grid-row: 1;
                grid-column: 1 / -1;
            }
            &--box-1 {
                grid-row: 2;
                grid-column: 1 / -1;
            }
            &--box-3,
            &--box-4 {
                grid-row: 3;
            }
            &--box-3 {
                grid-column: 1 / 3;
            }
            &--box-4 {
                grid-column: 3 / -1;
            }
        }
    }

    &__grid-style-2 {
        &--box-1 {
            grid-column: 1 / 3;
            grid-row: 2;
        }

        @include respond(tab-port) {
            &--box-2 {
                grid-row: 3;
                grid-column: 1 / -1;
            }
            &--box-1 {
                grid-row: 2;
                grid-column: 1 / -1;
            }
            &--box-3,
            &--box-4 {
                grid-row: 1;
            }
            &--box-3 {
                grid-column: 1 / 3;
            }
            &--box-4 {
                grid-column: 3 / -1;
            }
        }
    }

}