@import '../main.styles.scss';

.stylized-header{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    transform: rotate(-8deg);

    margin: 5rem 0;
    text-align: center;
    text-indent: 2rem;
    font-family: $heading-font;

    max-width: 50rem;

    @include respond(phone) {
        //max-width: 100rem;
    }

    &__hidden {
        visibility: hidden;
    }

    &__top-text {
        color: $color-white;
    }

    &--1 {
        &__hidden,
        &__top-text,
        &__bottom-text,
        &__bg-fill {
            position: absolute;
        }
    
        &__top-text {
            z-index: 1;
        }
    
        &__bottom-text {
            color: $color-brand-orange;
            -webkit-text-stroke: 1.7rem $color-brand-orange;
            @include text-drop-shadow;
        }
    
        &__top-text,
        &__bottom-text {
            font-size: 5.3rem;
            line-height: .8;
        }
    }

    &--3 {
        @include respond(phone) {
            align-items: start;
        }
        &__top-text {
            font-size: 3.5rem;
            line-height: 1;
            @include text-drop-shadow;
        }
        &__hidden {
            @include respond(phone) {
                max-width: 0rem;
            }  
        }
    }
    &--2 {
        &__top-text {
            font-size: 3.5rem;
            line-height: 1;
            @include text-drop-shadow;
        }
    }

    &__logo {
        max-width: 20rem;
        text-indent: -12rem;
        margin-left: 12rem;
    }   
}