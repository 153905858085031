@import '../main.styles.scss';

.custom-button {
    @include gradient-brand-twocolor;
    @include basic-drop-shadow;
    width: fit-content;
    padding: 1rem 3rem;
    margin: 2rem 0;
    border-radius: 1rem;

    color: $color-white;
    font-weight: bold;

    &__link {
        
        &:visited,
        &:active,
        &:link,
        &:hover {
            text-decoration: none;
            color: $color-white;
        }
    }

    &:hover{
        @include bounce-animation;
        @include hover(yellow, box);
        cursor: pointer;
    }
}