@import "../main.styles.scss";

.header {
  display: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &--left {
    display: flex;
    align-items: center;
  }
  

  /********************
        mobile nav
  ********************/

  &__mobile {
    &__checkbox {
      display: none;
    }
    &__menu {
      width: 100%;

      &__label {
        width: 3.5rem;
        height: 3.5rem;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 1;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        margin: 1rem;

        transition: all 1s;

        &:hover {
          cursor: pointer;
        }

        &::after,
        &::before {
          content: '';
          transition: trasnform 1s;
        }
      }

      &__icon {
        transition: all 1s;
      }
  
      &__icon,
      &__label::before,
      &__label::after {
        display: block;
        width: 100%;
        height: .5rem;
        background-color: $color-white;
        @include basic-drop-shadow;
      }

      &--part {
        display: flex;
        justify-content: center;
        &:not(:last-child) {
          margin-bottom: 5rem;
        }
      }

      &__dropmenu {
        position: fixed;
        top: -100%;
        right: 0;
  
        width: 100%;
        height: 1vh;
        background-color: lightgrey;

        transition: all .8s;
      }
      
    }
 

    /*****************************
      mobile menu functionality
    *****************************/

    &__checkbox:checked ~ &__menu__dropmenu {
      background-color: $color-brand-orange;
      top: 0;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__checkbox:checked ~ &__menu__label {
      transform: rotate(720deg);
      justify-content: center;

        &::after,
        &::before {
          position: absolute;

          animation-name: navTopBottomBar;
          animation-duration: .8s;
          animation-timing-function: ease-in-out;
        }

        &::before {
          transform: rotate(405deg);
        }
        &::after {
          transform: rotate(-405deg);
        }
    }

    &__checkbox:checked ~ &__menu__label &__menu__icon {
        position: absolute;
        width: 20%;
        border-radius: 2rem;
    }

  }
  
}
