@import '../main.styles.scss';

.portfolio-item {

    &__tool-bar {
        position: relative;
        display: flex;
        align-items: center;

        background-color: lightgrey;
        padding: $basic-padding;

        &--title {
            font-weight: bold;
            color: $color-brand-orange;
            font-size: 1.8rem;
        }

        &--tool {
            padding-left: 1rem;
        }

        &--tool-img {
            width: 4rem;
        }

        &--tooltiptext {
            position: absolute;
            top: 0;
            margin-left: 1rem;
            padding: .1rem .8rem;

            @include translucent-white-background;
            @include basic-drop-shadow;
        }

        ///////////////////////////////
        ///  tool tip functionality///
        //////////////////////////////

        &--tooltiptext {
            visibility: hidden;
        }

        &--tool:hover &--tooltiptext {
            visibility: visible;
        }
    }

    &__process {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        background-color: lightgrey;
        padding: $basic-padding;

        &--title {
            text-align: center;
            .stylized-header__top-text {
                color: $color-brand-orange;
            }
            margin-bottom: 8rem;

            @include respond(phone) {
                margin-bottom: 0rem;
            }
        }

        &__part {
            display: flex;
            margin: 5rem 0;
            justify-content: space-between;
            align-items: center;

            @include respond(phone) {
                flex-direction: column;
            }

            &:not(:last-child) {
                margin-bottom: 2rem;
            }

            &--image {
                background-color: grey;
                @include basic-drop-shadow;

            }

            &--side {
                //width: 48%;

                &--01 {
                    max-width: 45%;
                    max-height: 100%;
                    width: 40rem;
                    height: 40rem;
                    background-color: $color-white;
                }
                &--02 {
                    width: 50%;
                }

                @include respond(phone) {
                    &--01 {
                        max-width: 100%;
                        width: 100%;
                    }
                    &--02 {
                        width: 100%;
                    }
                }
            }

            &--title {
                color: $color-brand-orange;
                padding: 3rem 0;
                line-height: 1.2;
                font-family: $body-font;
                font-weight: bold;
            }
        }
    }

    &__tool-bar,
    &__process {
        @include translucent-white-background;
        @include basic-drop-shadow;
    }
}