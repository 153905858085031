@import '../main.styles.scss';

.alt-heading {
    background-color: lightgrey;

    @include basic-drop-shadow;
    @include translucent-white-background;

    &--wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__title {
        text-align: center;
        align-self: center;
    }

    &__subtitle {
        color: $color-brand-orange;
        padding: 3rem 0;
        line-height: 1.2;
    }
}