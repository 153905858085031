/********************
        GRID
********************/
.App {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns:
    [full-start] minmax(6rem, 1fr)
    [center-start] repeat(8, [col-start] minmax(min-content, 14rem) [col-end])
    [center-end] minmax(6rem, 1fr) [full-end];
}

header {
  grid-column: center-start/center-end;
  grid-row: 1;

  
}
main {
  grid-column: center-start/center-end;
  grid-row: 2;
}
footer {
  grid-column: center-start/center-end;
  grid-row: 3;
}

header,
main,
footer {
  @include respond(tab-port) {
    grid-column: full-start / full-end;
    padding: 1rem;
  }
}


