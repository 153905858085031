@import "../main.styles.scss";

.custom-card {
    //background-color: lightgrey;
    @include basic-drop-shadow;
    @include translucent-white-background;
    padding: $basic-padding;
    font-size: 1.2rem;

    width: 28rem;

    @include respond(between-phone-tab) {
        &:not(:last-child) {
            margin-bottom: 5rem;
        }
    }

    &--wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 100%;
    }

    &__title {
        text-align: center;
        line-height: 1.2;
        margin-bottom: 1rem;
    }

    &__image {
        //background-color: grey;
        width: 20rem;
        height: 20rem;

        display: flex;
        justify-content: center;
        align-items: center;

        &--icon {
            height: 16rem;
            @include image-drop-shadow;
        }
    }

    &__list {
        list-style-type: none;
        align-self: start;
    }
}