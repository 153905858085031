@import '../main.styles.scss';

.about {
    &__hero {
        @include respond(phone) {
            margin-bottom: 45rem !important;
        }
        @include respond(small-phone) {
            margin-bottom: 50rem !important;
        }
    }
}