@import "../main.styles.scss";

.highlights {
    position: relative;

    &__cards {
        display: flex;
        justify-content: space-between;

        @include respond(between-phone-tab) {
            flex-direction: column;
            align-items: center;
        }
    }
}