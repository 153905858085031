@import "../main.styles.scss";

.gallery {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
        align-self: start;
    }
}