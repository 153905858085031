@import '../main.styles.scss';

.similar-projects {
    &__image-holder {
        display: flex;
        justify-content: space-between;

        max-width: 80rem;
        width: 100%;

        @include respond(small-phone) {
            flex-direction: column;
            align-items: center;
        }

        &--wrapper {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }

        &__image-box {
            position: relative;
            transition: all .3s;
            background-color: $color-white;
            //width: 20rem;
            //height: 20rem;
            width: 30%;
            padding-bottom: 30%;
            @include basic-drop-shadow;

            @include respond(small-phone) {
                &:not(last-child){
                    margin-bottom: 2rem;
                }
            }

            &:hover {
                cursor: pointer;
                @include basic-drop-shadow-hover;
                transform: rotate(-5deg) scale(1.05);
                transform-origin: center;
            }

            &--image {
                position: absolute;
                //background-color: orange;
                width: 100%;
                height: 100%;
            }
        }
    }
}