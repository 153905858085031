/********************
    VARIABLES
********************/

/******* colors ********/
$color-brand-yellow: #d9c328;
$color-brand-orange: #f26222;
$color-brand-redorange: #ed2324;

$color-white: #fff;
$color-black: #000;

$basic-padding: 2rem 3rem;

/******* fonts ********/
$body-font: 'Montserrat', sans-serif;
$heading-font: 'Russo One', sans-serif;

/********************
    FUNCTIONS
********************/

/********************
    MIXINS
********************/
@mixin gradient-brand-tricolor {
    background: linear-gradient(145deg, $color-brand-yellow 0%, $color-brand-orange 50%, $color-brand-redorange 100%);
}
@mixin gradient-brand-twocolor {
    background: linear-gradient($color-brand-yellow 0%, $color-brand-orange 100%);
}

@mixin translucent-white-background {
    background-color: rgba($color-white, .75);
}

@mixin basic-drop-shadow {
    box-shadow: 0 1rem 1.5rem rgba($color-black, .3);
}
@mixin basic-drop-shadow-hover {
    box-shadow: 0 1rem 1.5rem rgba($color-brand-yellow, .3);
}
@mixin basic-drop-shadow-focus {
    box-shadow: 0 0rem 3.5rem rgba($color-brand-yellow, .8);
}

@mixin image-drop-shadow {
    filter: drop-shadow(0 .8rem .7rem rgba($color-black, .3));
}
@mixin image-drop-shadow-focus {
    filter: drop-shadow(0 1rem 1.5rem rbga($color-brand-yellow, 1));
}

@mixin text-drop-shadow {
    filter: drop-shadow(0 .8rem .7rem rgba($color-black, .5));
}
@mixin text-drop-shadow-hover {
    &:hover {
        filter: drop-shadow(0 .8rem .7rem rgba($color-brand-yellow, .5));
    }
}

@mixin hover($color, $shadowtype) {
    transition: all .3s;
    @if $color == yellow {
        @if $shadowtype == box {
            box-shadow: 0 1rem 2rem rgba($color-brand-yellow, .5);
        }
        @if $shadowtype == filter {
            filter: drop-shadow(0 1rem 2rem rgba($color-brand-yellow, .5));
        }
    }
}

@mixin bounce-animation {
    animation-name: bounce;
    animation-duration: 1s;
}

@mixin send-animation {
    animation-name: sending;
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
    //animation-timing-function: cubic-bezier( 0.85, 0.29, 1, 1 );
}

@mixin jello-animation {
    animation-name: jello;
    animation-duration: 1.3s;
    transform-origin: center;
}
