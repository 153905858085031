@import "../main.styles.scss";


.contact-form {
    display: flex;
    justify-content: space-around;

    width: 100%;
    @include translucent-white-background;
    @include basic-drop-shadow;

    padding: $basic-padding;

    @include respond(tab-port) {
        flex-direction: column;
        align-items: center;
    }

    &__side {
        position: relative;
        width: 100%;

        &--1 {
            width: 60%;
        }

        &--2 {
            width: 30%;
            //background-color: lightgrey;
            background-color: $color-brand-orange;
            padding: $basic-padding;
        }

        @include respond(tab-port) {
            &--1,
            &--2 {
                width: 100%;
            }

            &--2 {
                order: -1;
            }
        }
    }

    &__header {
        margin-bottom: 2rem;

        &--title {
            margin-bottom: 2rem;
        }

        &__icon-text {
            &--link {
                fill: $color-brand-orange;
                a {
                    color: $color-brand-orange;
                }
            }
        }
    }

    &__side-title {
        color: $color-white;
        margin-bottom: 4rem;
        &--title {
            font-weight: bold;
            font-size: 1.8rem;
            display: block;
        }
        &--sub-title {
            font-size: 1.6rem;
        }
    }

    &__icon-text {
        &__links {
            height: 70%;
        }
        &--link {
            fill: $color-white;
            a {
                color: $color-white;
            }
            &:not(:last-child) {
                margin-bottom: 2rem;
            }
        }
    }

    &__group {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 1;

        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }

    &__loading {
        position: absolute;
        z-index: -1;
        opacity: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 50%;

        transition: all .3s;

        &--active {
            width: 100%;
            height: 50%;
            z-index: 100000;
            opacity: 1;
        }

        &--wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 20rem;
            height: 20rem;
            @include translucent-white-background;
            @include basic-drop-shadow;
            overflow: hidden;
        }

        &__marker {
            height: 5rem;
            width: 100%;
            &--icon {
                fill: $color-brand-orange;
                @include send-animation;
                width: 4rem;
            }
        }
    }

    &__state {
        position: absolute;
        z-index: -100;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 0;
        height: 0;
        padding: 1rem;

        transition: all .3s;

        //color: $color-brand-orange;
        @include translucent-white-background;
        @include basic-drop-shadow;

        &--active {
            width: 100%;
            height: 80%;
            z-index: 10000;
        }
    }

    &__input-shadow {
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        width: 100%;
        height: 60%;
        background-color: $color-brand-yellow;
        padding: 1rem 1.5rem;
        border-radius: 1rem;
        @include basic-drop-shadow;
    }

    &__textarea {
        height: 10rem;
    }

    &__label {
        color: $color-brand-orange;
        margin-left: 1.5rem;
        transition: all .5s;
    }

    &__error {
        padding: 0 .5rem;
    }

    &__input,
    &__textarea {
        border-radius: .3rem;
        border: none;
        padding: 1rem 1.5rem;
        width: 100%;
        font-family: inherit;

        &:focus,
        &:active {
            outline: none;
            border: 1px solid $color-brand-yellow;
        }
    }

    &__textarea + &__input-shadow {
        height: 81%;
    }

    &__input:focus + &__input-shadow,
    &__textarea:focus + &__input-shadow {
        @include basic-drop-shadow-focus;
    }

    &__input:placeholder-shown ~ &__label,
    &__textarea:placeholder-shown ~ &__label {
        opacity: 0;
        visibility: hidden;
        transform: translateY(-4rem);
    }

    //captcha box
    &__g-recaptcha {
        display: block;
        margin-top: 1rem;
    }
}