@import '../main.styles.scss';

.home {
    &__hero {
        @include respond(phone) {
            margin-bottom: 18rem !important;
        }
        @include respond(small-phone) {
            margin-bottom: 25rem !important;
        }
    }
}