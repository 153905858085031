/***************************
    RESPONSIVE BREAKPOINTS

  runs off of em
  1em = 16px

  0 - 600px     = Phone
  601 - 900px   = Tablet Portrait
  901 - 1200px  = Tablet Landscape

  [1200 - 1800] = basic desktop styling
  1800+ px      = large desktops


  $breakpoints:

    - small-phone
    - phone
    - tab-port
    - tab-land
    - big-desktop

****************************/

@mixin respond($breakpoint) {
  @if $breakpoint == small-phone {
    @media only screen and (max-width: 31.25em) { @content }; //500px
  }
  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) { @content }; //600px
  }
  @if $breakpoint == large-phone {
    @media only screen and (max-width: 43.75em) { @content };  //700px
  }
  @if $breakpoint == between-phone-tab {
    @media only screen and (max-width: 50em) { @content }; //800px
  }
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) { @content }; //900px
  }
  @if $breakpoint == tab-land {
    @media only screen and (max-width: 75em) { @content }; //1200px
  }
  @if $breakpoint == big-desktop {
    @media only screen and (max-width: 112.5em) { @content }; //1800px
  }
}

/********************
        BASE
********************/

/******

REM sizing used for styling
base size: 1rem = 10px

def:
    - base browser sizes at 16px
    - changed to 62.5% to equal 10px
    - conversions are (desired px size)/16px
        - change to percent

******/

//BROWSER RESET
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
html {
  font-size: 62.5%; //1rem = 10px
}
body {
  box-sizing: border-box;
}

/********************
    ANIMATIONS
********************/

@keyframes bounce {
  0% {
    transform: scale(1) translateY(0);
  }
  10% {
    transform: scale(1.2, 0.8);
  }
  30% {
    transform: scale(0.8, 1.1) translateY(-5px);
  }
  50% {
    transform: scale(1.2, 0.8) translateY(2px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes sending {
  0% {
    transform: translateX(-100%) skewX(0) scale(1);
  }
  30% {
    //transform: scale(1.1, 0.7);
    transform: translateX(200%) skewX(30deg) scale(1.2, 0.7);
  }
  35% {
    transform: translateX(200%) skewX(-10deg) scale(1.2, 0.7);
  }
  40% {
    transform: translateX(200%) skewX(0deg) scale(1);
  }
  45% {
    transform: translateX(200%) skewX(8deg) scale(1);
  }
  50% {
    transform: translateX(200%) skewX(0) scale(1);
  }
  60% {
    transform: translateX(200%) skewX(0deg) scale(1);
  }
  100% {
    transform:translateX(700%) skewX(50deg) scale(1.4, 0.4);
  }
}

@keyframes jello {
  11% {
    transform: none;
  }
  22% {
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33% {
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44% {
    transform: skewX(-3.13deg) skewY(-3.13deg);
  }
  55% {
    transform: skewX(1.56deg) skewY(1.56deg);
  }
  67% {
    transform: skewX(-0.8deg) skewY(-0.8deg);
  }
  78% {
    transform: skewX(0.4deg) skewY(0.4deg);
  }
  89% {
    transform: skewX(-0.2deg) skewY(-0.2deg);
  }
  100% {
    transform: none;
  }
}

//animation set for nav icon//

@keyframes navTopBottomBar {
  14.3% {
    width: 100%;
  }
  57.2% {
    //first full spin
    width: 20%;
    border-radius: 2rem;
  }
  100% {
    //second full spin
    width: 100%;
    border-radius: 0;
  }
}

/********************
    TYPOGRAPHY
********************/
body {
  line-height: 1.7;
  font-size: 1.4rem;
  font-family: $body-font;
}

h1, h3 {
  font-family: $heading-font;
  font-weight: 100;
}

h3 {
  font-size: 2rem;
  color: $color-brand-orange;
}

p {
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
}

/********************
    UTILITIES
********************/
.section-margin {
  margin: 3rem 0;
}

.wrapper-padding {
  padding: 6rem 8rem;

  @include respond(tab-port) {
    padding: 4rem 6rem;
  }
  @include respond(phone) {
    padding: 3rem 4rem;
  }
  @include respond(small-phone) {
    padding: 2.5rem 3rem;
  }
}

/********************
    SHARED STYLES
********************/
.translucent-bg {
  @include translucent-white-background;
  @include basic-drop-shadow;
}