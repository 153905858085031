@import '../main.styles.scss';

.icon-text-link {
    display: flex;
    

    &--icon {
        margin-right: 1rem;
        display: flex;
        align-items: center;
        //fill: $color-white;
    }

    &--svg {
        width: 2.5rem;
        height: 2.5rem;
        @include text-drop-shadow;
    }

    &--link {
        text-decoration: none;
    }


    &:hover {
        @include bounce-animation;
        cursor: pointer;
    }

}