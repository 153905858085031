@import '../main.styles.scss';

.hero {
    position: relative;
    min-height: 56rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &--wrapper {
        display: flex;
        justify-content: space-between;

        @include respond(tab-port) {
            //flex-direction: column;
            padding: 0 !important;
            padding-right: 2rem !important;
        }

        @include respond(phone) {
            flex-direction: column;
            justify-content: start;
            align-items: center;
            height: 60rem;
        }

        @include respond(small-phone) {
            height: 53rem;
        }

        &__altMobileStyle {
            @include respond(phone) {
                justify-content: center;
                padding: 0 !important;
                height: auto;
            }

            @include respond(small-phone) {
                height: auto;
            }
        }
    }

    @include respond(tab-port) {
        //background-color: transparent !important;
        //box-shadow: none !important;
        min-height: 50rem;
    }

    &__side {
        width: 48%;

        @include respond(tab-port) {
            width: 100%;
        }

        &--01 {
            @include respond(tab-port) {
                //@include translucent-white-background;
                //height: 80vh;
                //max-height: 50rem;
                //@include basic-drop-shadow;
            }

            &__altMobileStyle {
                @include respond(phone) {
                    //position: relative;
                    display: flex;
                    justify-content: center;
                }
            }
        }

        &--02 {
            @include respond(tab-port) {
                margin-left: 10rem;
                margin-top: 12rem;
                padding-left: 1rem;
            }
            @include respond(phone) {
                margin-left: 0;
                padding-left: 0;
                width: 90%;
            }

            &__altMobileStyle {
                @include respond(phone) {
                    position: relative;

                    .hero__title {
                        top: -12rem !important;
                    }
                }


                @include respond(tab-port) {
                    margin-left: 2rem;
                    padding: 0 2rem 5rem 2rem;
                }

                @include respond(phone) {
                    .hero__title {
                        //top: -12rem !important;
                    }
                }
            }
        }
    }

    &__image {

        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 100%;

        &__bunny-image {
            position: relative;
            bottom: 0;
            //height: 100%;
            max-height: 100%;
            @include image-drop-shadow;

            @include respond(tab-port) {
                left: -5.6rem;
                position: absolute;
            }

            @include respond(large-phone) {
                transform: scale(.95);
                left: -6.4rem;
                bottom: -1.4rem;
            }

            @include respond(phone) {
                transform: scale(1);
                left: -6.7rem;
                bottom: 0;
            }

            @include respond(small-phone) {
                //transform: scale(.7);
                //left: -10.7rem;
                //bottom: -8.8rem;
                left: -5.9rem;
                bottom: 0;
                //width: 100%;

            }

            &--wrapper {
                position: absolute;
                bottom: 0;
                left: 0;

                width: 50%;
                height: 110%;

                display: flex;
                justify-content: center;

                //background-color: pink;
                //overflow: hidden;

                @include respond(tab-port) {
                    //height: 50vh;
                    //width: 100%;
                    //height: 100%;
                }

                @include respond(phone) {
                    //width: 100%;
                    //height: 133%;
                }
            }
        }

        &__altMobileStyle {

            width: 100%;
            height: 100%;

            &--wrapper {
                width: 100%;
                height: 100%;
                //background-color: grey;

                @include respond(phone) {
                    position: absolute;
                    width: 95%;
                    height: 40rem;
                    top: 10rem;
                }
            }
        }

    }

    &__title {
        position: absolute;
        top: 5rem;
        right: 30rem;

        @include respond(small-phone) {
            right: 47%;
        }
    }

    &__subtitle {
        color: $color-brand-orange;
        padding: 3rem 0;
        line-height: 1.2;
        a {
            color: $color-brand-orange;
            &:visited,
            &:active {
                color: $color-brand-orange;
            }
        }

        @include respond(phone) {
            color: $color-white;
        }

        &__altMobileStyle {
            color: $color-brand-orange;
        }
    }

    &--info-wrapper {
        margin-top: 10rem;
        @include respond(phone) {
            position: absolute;
            width: 100%;
            left: 0;
            bottom: -22rem;

        }

        @include respond(small-phone) {
            bottom: -27rem;
        }

        &__altMobileStyle {
            @include respond(phone) {
                position: relative;
                //height: 200%;
                width: 100%;
                bottom: -28rem;
                margin-bottom: 30rem;
            }
        }

    }

}

.about__hero {
    .hero--info-wrapper {
        @include respond(tab-port) {
            position: relative;
            z-index: 1;
        }
    }
    .hero--info-wrapper {
        @include respond(phone) {
            padding-top: 17rem;
            //bottom: -40rem;
        }

        @include respond(small-phone) {
            //bottom: -45rem;
            padding-top: 5rem;
        }
    }
    .hero__image__bunny-image {
        @include respond(large-phone){
            bottom: -1.9rem;
        }
        @include respond(phone){
            bottom: 0;
        }
    }
}

